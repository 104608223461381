import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'

export default ({
    children,
    to,
    dangerouslySetInnerHTML,
    className,
    onClick
}) => {
    const isExternal = to.startsWith('http')

    const commonProps = {
        className,
        onClick,
    }

    if(isExternal){
        return (
            <a 
                href={to}
                {...commonProps}
                target="_blank"
            >
                { children }
            </a>
        )
    }

    const handleClick = e => {
        if(onClick){
            onClick(e)
        }

        if(typeof window !== 'undefined'){
            //window.scrollTo({top: 0, behavior: 'smooth'})
        }
    }

    return (
        <TransitionLink 
            to={to} 
            exit={{ 
                length: 0.5,
                trigger: () => {
                    setTimeout(() => {
                        if(typeof window !== 'undefined'){
                            window.scrollTo({top: 0})
                        }
                    },300)
                }
            }} 
            entry={{ 
                length: 0.3,
                appearAfter: 0.3
            }}
            className={className}
            onClick={handleClick}
            preventScrollJump
        >
            { children }
        </TransitionLink>
    )
}