import React, { useRef, useEffect } from 'react'

export default ({
    close,
    data,
    isVisible
}) => {
    const videoRef = useRef(null)

    useEffect(() => {
        if(!isVisible && videoRef.current){
            videoRef.current.pause()
        }

        if(isVisible){
            setTimeout(() => {
                if(videoRef.current){
                    videoRef.current.play()
                }
            },500)
        }

        if(typeof document !== 'undefined'){
            document.body.style.overflow = isVisible ? 'hidden' : 'auto'
        }
    }, [isVisible])

    return (
        <>
            <button onClick={close}>
                <i className={'fe fe-x'} />
            </button>
            <div className="video-container">
                { (data && data.mediaItemUrl) ? 
                    <video 
                        ref={ref => videoRef.current = ref} 
                        controls 
                        preload="auto" 
                        data-setup='{}'
                        key={data.mediaItemUrl}
                    >
                        <source src={data.mediaItemUrl} type="video/mp4" />
                    </video>
                : null }
            </div>
        </>
    )
}