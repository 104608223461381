import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/globals/link'
import { MainContext } from "context/context"

import LinkedInIcon from 'images/linkedin-icon-footer.png'
import InstagramIcon from 'images/instagram-icon-footer.png'

export default () => {
	const {
		options
	} = useContext(MainContext)

	const {
		logos,
		locations,
		locationsTitle,
		socials
	} = options

	const { nodes: menuItems } = useStaticQuery(graphql`
        query {
            siteData {
                menu (
          id: "TWVudTo0MA=="
        ) {
          menuItems {
            nodes {
              label
              title
              url
              childItems {
                nodes {
                  label
                  title
                  url	
                }
              }
            }
          }
        }
            }
        }
	`).siteData.menu.menuItems

	return (
		<footer>
			<div className="container" id={'vacancies'}>
				<div className="top">
					<h3>
						{locationsTitle}
					</h3>
					<ul className="locations">
						{locations.map((location, index) => {
							return (
								<li key={index}>
									<a href={location.url} target="_blank">
										<h2 className="h1">
											{location.name}
										</h2>
									</a>
								</li>
							)
						})}
					</ul>
				</div>
				<div className="bottom">
					<img className="logo" src={logos.inverted.sourceUrl} />
					<ul className={'socials'}>
						{ socials.map(socialLink => {
							const {
								type,
								url
							} = socialLink

							let image = null

							switch(type){
								case 'linkedin':
									image = LinkedInIcon
									break
								case 'instagram':
									image = InstagramIcon
									break
							}


							return (
								<li key={socialLink.url}>
									<a href={url} target="_blank">
										<img src={image} />
									</a>
								</li>
							)
						})}
					</ul>
					<ul className="menu">
						{menuItems.map((item, index) => {
							const {
								url,
								label
							} = item

							return (
								<li key={url}>
									<a href={url}>
										{label}
									</a>
								</li>
							)
						})}
					</ul>
					<span className={'attribution'}>
						Website by <a href="https://yourcreative.com.au" target="_blank">Your Creative</a>.
					</span>
				</div>
			</div>
		</footer>
	)
}
