import React, { useContext, useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { MainContext } from "context/context"
import { isActiveLink } from 'utils'
import 'vendor/hamburgers.css'
import Link from 'components/globals/link'

import {Link as ScrollLink } from "react-scroll"

import Button from 'components/globals/button'

export default ({
	location
}) => {
	const {
		options,
		isHeaderInverted,
		hasScrolled,
		checkInvertedHeaderOffsets,
		componentRefs,
	} = useContext(MainContext)

	const {
		logos,
		linkToCareersSite
	} = options

	useEffect(() => {
		setTimeout(() => {
			checkInvertedHeaderOffsets()
		},1000)

		if(location && typeof window !== 'undefined'){
			const _isOnHomePage = !!(!location.pathname || location.pathname === '/')

			setIsOnHomePage(_isOnHomePage)

			if(location.search){
				const elementId = location.search.replace('?scrollTo=','')

				window.scrollTo({
					behavior: "smooth",
					top: document.getElementById(elementId).getBoundingClientRect().top - document.body.getBoundingClientRect().top
				})
			}
		}
	}, [location])

	const [selectedChildItems, setSelectedChildItems] = useState([])
	const [hoveredMenuItemIndex, setHoveredMenuItemIndex] = useState(-1)
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	const [isOnHomePage, setIsOnHomePage] = useState(true)

	let headerClassName = 'header'

	if(isHeaderInverted || isMobileMenuOpen){
		headerClassName += ' inverted'
	} 

	if(hasScrolled && !isMobileMenuOpen){
		headerClassName += ' has-scrolled'
	} 

	/*
    const { nodes: menuItems } = useStaticQuery(graphql`
        query {
            siteData {
                menu (
					id: "TWVudTo0"
				) {
					menuItems {
						nodes {
							label
							title
							url
							childItems {
								nodes {
									label
									title
									url	
								}
							}
						}
					}
				}
            }
        }
	`).siteData.menu.menuItems
	*/

	const handleHover = (index, items = []) => {
		if(items.length){
			setSelectedChildItems(items)
			setHoveredMenuItemIndex(index)
		}else{
			setHoveredMenuItemIndex(-1)
		}
	}

	let headerContainerName = 'header-container'

	const menuItems = [
		{
			label: 'Real talk',
			id: 'realTalk',
			offset: -500
		},{
			label: `Stories`,
			id: `stories`,
			offset: -320
		},{
			label: 'Career Opportunities',
			id: 'vacancies',
			offset: -250
		}
	]

	const LinkElement = isOnHomePage ? ScrollLink : Link

	return (
		<div 
			className={headerContainerName}
			onMouseLeave={() => handleHover(-1)}
		>
			<header className={headerClassName}>
				<div className="container">
					<div className="content">
						<Link
							to={'/'} 
							className={'logo'}
						>
							<img className="normal" src={logos.standard.sourceUrl} />
							<img className="inverted" src={logos.inverted.sourceUrl} />
						</Link>
						<menu>
							<ul>
								{ menuItems.map(item => {
									const {
										id,
										label,
										offset
									} = item

									const to = isOnHomePage ? id : '/?scrollTo='+id

									return (
										<li key={item.id}>
											<LinkElement
												activeClass="active"
												to={to}
												spy={true}
												smooth={true}
												offset={offset}
												duration={500}
											>
												{ label }
											</LinkElement>
										</li>
									)
								})}

							</ul>
							{ /*
							<ul>
								{ menuItems.map(item => {
									const {
										url,
										label
									} = item

									return (
										<li className={isActiveLink(location, url) ? 'active' : '' } key={url}>
											<Link to={url}>
												{ label }
											</Link>
										</li>
									)
								})}
							</ul>
							*/ }
						</menu>
						<div className="cta">
							<Button
								label={'Find a career'}
								url={linkToCareersSite}
							/>
						</div>
					</div>
				</div>
			</header>
		</div>
	)
}
