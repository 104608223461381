import React from 'react'
import Link from 'components/globals/link'

export default ({ 
    label, 
    url, 
    onClick, 
    type = 'link',
    inverted,
    green,
    compact,
    icon,
    iconPosition
}) => {
    const buttonLabel = label || 'Read more'

    let className = 'button'

    if(inverted){
        className += ' inverted'
    }

    if(!label){
        className += ' icon-only'
    }

    if(compact){
        className += ' compact'
    }

    if(green){
        className += ' green'
    }

    const buttonContent = (
        <>
            <i className={'slash'} />
            { label ?
                <span>
                    { buttonLabel }
                    { icon &&
                        <img src={icon} />
                    }
                </span>
            : null }
        </>
    )

    if(type === 'button' || !url){
        return (
            <button 
                className={className}
                onClick={onClick}
            >
                { buttonContent }
            </button>
        )
    }else if(url.startsWith('https')) {
        return (
            <a 
                href={url} 
                onClick={onClick} 
                className={className}
                target={'_blank'}
            >
                { buttonContent }
            </a>
        )
    } else {
        return (
            <Link 
                to={url} 
                className={className}
            >
                { buttonContent }
            </Link>
        )
    }
}
